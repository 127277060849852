<template>
  <div>
    <b-card-actions
      ref="cardAction"
      title="Filters"
      @refresh="resetColFilters"
      @remove="resetColFilters"
    >
      <b-row>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Login ID</label>
          <b-form-input
            id="downline_loginid"
            v-model="downline_loginid"
            placeholder="Login ID"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Mobile</label>
          <b-form-input
            id="lastserverid"
            v-model="mobile"
            placeholder="Mobile"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Name</label>
          <b-form-input
            id="lastserverid"
            v-model="name"
            placeholder="Name"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Select Rank</label>
          <v-select
            v-model="rank"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="rankOptions"
            class="w-100"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2 mt-2"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="handleSearch"
          >Search
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h4>
          All User List
        </h4>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="btn-icon  text-right"
          @click="$router.push('/user/add')"
        >
          <feather-icon
            icon="PlusIcon"
            svg-classes="h-4 w-4"
          />

         New Registartions
        </b-button>
      </b-card-header>
      <b-table-simple
        :per-page="perPage"
        hover
        striped
        bordered
        responsive
        class="rounded-bottom mb-0"
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th>Login ID</b-th>
            <b-th>Name</b-th>
            <b-th>Rank/Plan</b-th>
            <b-th>Contact Details</b-th>
            <b-th>Status</b-th>
            <b-th>E-wallet</b-th>
            <b-th>Action</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-show="showLoading"
            class="b-table-busy-slot"
          >
            <b-td colspan="11">
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle" />
                <strong>Loading...</strong>
              </div>
            </b-td>
          </b-tr>
          <template v-if="list.length>0 && !showLoading">
            <b-tr
              v-for="(tr, indextr) in list"
              :key="indextr"
            >
              <b-td>
                {{ tr.loginid }}
              </b-td>
              <b-td>{{ tr.name }}</b-td>
              <b-td>{{ tr.rank_name }}<br>{{ tr.plan_name }}</b-td>
              <b-td>{{ tr.mobile }}<br>{{ tr.email }}</b-td>
              <b-td>
                <toggle-button
                  :value="(tr.user_status==1)?true:false"
                  :sync="true"
                  :labels="{checked: 'Active', unchecked: 'Inactive'}"
                  :width="70"
                  @change="openConfirm(tr.loginid,tr.user_status,tr.index)"
                />
              </b-td>
              <b-td>
                <b-button
                  v-if="!tr.show_balance"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-tooltip.hover
                  variant="flat-success"
                  title="Check E-wallet Status"
                  class="btn-icon"
                  @click.stop="getBalance(tr.loginid,tr.index)"
                >
                  <feather-icon icon="BriefcaseIcon" />
                </b-button>
                <template v-else>
                  <b-badge :variant="(tr.balance>0)?'light-success':'light-danger'">
                    {{ tr.balance }} MYR
                  </b-badge>
                </template>
              </b-td>
              <b-td>
                <feather-icon
                  icon="EditIcon"
                  svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  @click.stop="editData(tr.id)"
                />
              </b-td>
            </b-tr>
          </template>
          <template v-else-if="!showLoading">
            <b-tr rowspan="10">
              <b-td
                colspan="11"
                class="text-center"
              >
                No data avilable
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
      <b-card-body>
        <b-pagination
          v-if="totalRows>0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BTableSimple,
  BCard,
  BCardHeader,
  BCardBody,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BBadge,
  BPagination,
  BFormCheckbox,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import SecureLS from 'secure-ls'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import UserService from '../../services/user.service'
import OperatorService from '../../services/operator.service'
import JwtService from '../../common/jwt.service'
import 'animate.css'

export default {
  components: {
    BTableSimple,
    BCard,
    BCardHeader,
    BCardBody,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BBadge,
    BPagination,
    BSpinner,
    BCardActions,
    BFormCheckbox,
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      showLoading: false,
      status: 'All',
      itemsPerPage: 10,
      isMounted: false,
      activePrompt: false,
      activeConfirm: false,
      downline_loginid: '',
      mobile: '',
      name: '',
      id: '',
      statusN: '',
      key: '',
      status: true,
      balance: 0.00,
      rank: { label: 'Select All Ranks', value: '' },
      rankOptions: [
        { label: 'Select All Ranks', value: '' },
        { label: 'Master', value: '12' },
        { label: 'Reseller', value: '14' },
        { label: 'Dealer', value: '15' },
      ],
      users: [],
    }
  },
  computed: {
    list() {
      let start = 0
      let end = 0
      if (this.currentPage == 1) {
        start = 0,
        end = start + this.perPage
      } else {
        start = this.currentPage * this.perPage - 10,
        end = this.currentPage * this.perPage
      }

      return this.users.slice(start, end)
    },
  },
  mounted() {
    this.isMounted = true
    this.getDownlineUserList()
  },
  methods: {
    handleSearch() {
      this.getDownlineUserList({
        name: this.name,
        downline_loginid: this.downline_loginid,
        rank_id: this.rank.value,
        mobile: this.mobile,
      })
    },
    openConfirm(id, status, key) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to change status?',
        showCancelButton: true,
        confirmButtonText: 'Yes',

        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__flipInX',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.changeStatus(id, status, key)
        }
      })
    },

    resetColFilters() {
      this.name = this.downline_loginid = this.rannk = this.mobile = ''
      this.getDownlineUserList()
      this.$refs.cardAction.showLoading = false
    },
    editData(id) {
      const ls = new SecureLS({ encodingType: 'aes', isCompression: false })
      ls.set('edit_user_id', id)
      this.$router.push('/user/edit')
      this.$emit('edit-info', id)
    },
    changeStatus(id, status, key) {
      this.users[key].user_status = !status
      UserService.changeStatus({ id, status: !status }).then(
        response => {
          this.$toast({
            component: ToastificationContent,

            props: {
              title: 'Status Change',
              icon: 'InfoIcon',
              text: response.message,
              variant: 'success',
            },
          }, {
            position: 'top-center',
          })
        },
        error => {},
      )
    },
    getBalance(id, key) {
      UserService.getUserBalnce(id).then(
        response => {
          this.users[key].show_balance = true
          this.users[key].balance = response.balance
        },
        error => {},
      )
    },
    getDownlineUserList(data) {
      this.showLoading = true
      UserService.getDownlineUsers(data).then(
        response => {
          this.users = response.DownlineUsers
          this.totalRows = response.DownlineUsers.length
          this.showLoading = false
        },
        error => {
          this.showLoading = false
        },
      )
    },
  },

}

</script>
